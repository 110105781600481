import React from 'react'
import './footer.css'
import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
import { IoLogoTwitter } from 'react-icons/io'

const footer = () => {
    return (
        <footer>
            

            <ul className='permalinks'>
                <li><a href="#">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#experience">Experience</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#testimonials">Testimonials</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>

            <div className="footer__socials">
            <a href="https://github.com/full-stack-dev-400" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
            <a href="https://www.linkedin.com/in/umair-asif-bezteq/" target="_blank" rel="noopener noreferrer"><BsLinkedin /></a>
            <a href="https://x.com/ranaumair284" target="_blank" rel="noopener noreferrer"><IoLogoTwitter /></a>
        </div>
            <div className="footer__copyright">
                <small>&copy;All rights reserved. </small>
            </div>
        </footer>
    )
}

export default footer;